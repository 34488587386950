<template>
  <tr>
    <td class="text-center">
      <i :class="notification.type_icon" class="font-scale-10 text-muted" />
    </td>
    <router-link v-if="notification.item_id && notification.type === 'task'"
                 :to="{ name: notification.route_name, params: { taskId: notification.item_id }}">
      <td class="nowrap-column">
        {{ notification.message }}1
      </td>
    </router-link>
    <router-link v-if="notification.item_id && notification.type === 'car'"
                 :to="{ name: notification.route_name, params: { carId: notification.item_id }}">
      <td class="nowrap-column">
        {{ notification.message }}2
      </td>
    </router-link>
    <td v-if="notification.item_id && notification.type !== 'car' && notification.type !== 'task'">
      {{ notification.message }}
    </td>
    <td class="nowrap-column">
      {{ notification.created_date }}
    </td>
  </tr>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "notification-item",

  props: {
    notification: {
      type: Object,
      default() {
        return this.$props.$_PaginationObject;
      },
    },
  },

  computed: {
    ...mapGetters("auth", ["authUser"]),
  },

  methods: {

  },
};
</script>

<style scoped>

</style>
